import React from 'react';
import * as Dropdown from 'react-components/src/components/dropdown';
import Avatar from 'react-components/src/components/avatar';
import Text from 'react-components/src/components/text';

import { usePageContainer } from 'react-components/src/ui/layout/page';

import type { UserWorkspace } from '@app/data/user-workspaces.query';

type Props =
  | { children: React.ReactNode; selectedWorkspace?: undefined }
  | { selectedWorkspace: UserWorkspace; children?: undefined };

const Trigger = ({ selectedWorkspace, children }: Props) => {
  const { isCollapsed } = usePageContainer();
  const employerName = selectedWorkspace?.employment?.employer?.name || 'HSA';

  return (
    <Dropdown.Trigger className={children ? '' : 'p-0 hover:bg-transparent rounded-md max-w-min'} asChild={!!children}>
      {children || (
        <div className="flex flex-row gap-2 items-center truncate bg-indigo-40/20 hover:bg-indigo-40/25 px-3 py-2 rounded-md">
          <div className="rounded-full aspect-square object-cover flex items-center justify-center">
            <Avatar name={employerName} src={selectedWorkspace?.employment?.employer.logo} size="xs" />
          </div>

          {!isCollapsed ? (
            <Text className="text-indigo-10 truncate w-min" size="lg">
              {employerName}
            </Text>
          ) : null}
        </div>
      )}
    </Dropdown.Trigger>
  );
};

export default Trigger;
