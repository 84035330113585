import styled from 'styled-components';
import Spin from '../../Spin';

const StyledOverlay = styled.div`
  transform: translateZ(0px);
`;

type Props = {
  dataTestId?: string;
};

const LoadingOverlay = ({ dataTestId }: Props): JSX.Element => (
  <StyledOverlay
    data-testid={dataTestId}
    className="flex items-center justify-center flex-col fixed inset-0 z-50 bg-white/90"
  >
    <Spin />
  </StyledOverlay>
);

export default LoadingOverlay;
