import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Modal from 'react-components/src/components/modal';
import Text from 'react-components/src/components/text';
import useMobileAgent from 'utils/src/hooks/use-mobile-agent';
import SquiggleBottomImage from '@assets/onboarding/squigglebottom.png';
import SquiggleTopImage from '@assets/onboarding/squiggletop.png';
import AppStoreBadgeImage from '@assets/appstore/app-store-badge.png';
import PlayStoreBadgeImage from '@assets/appstore/google-play-badge.png';

const MobileAppStoresModal = ({ children }: { children: JSX.Element }) => {
  const [open, setOpen] = useState(true);
  const { isMobile, platform } = useMobileAgent();

  const handleOpenChange = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  if (!isMobile) return children;

  return (
    <>
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1497613952" />
      </Helmet>
      <Modal.Root open={open} onOpenChange={handleOpenChange}>
        <Modal.Content
          className="rounded-none bg-indigo-100 bg-no-repeat h-full [&_svg]:text-white"
          style={{
            backgroundImage: `url('${SquiggleBottomImage}'), url(${SquiggleTopImage})`,
            backgroundPosition: `-100px 100%, calc(100% + 182px) 0px`,
          }}
        >
          <div className="mt-12 text-center">
            <Text as="p" type="header-1" className="mb-8 text-grayscale-0 leading-tight">
              Get the full Benepass experience on the app!
            </Text>
            <Text as="p" type="body" size="lg" className="mb-4 text-grayscale-0 leading-tight">
              The app has several features not available on the mobile website, including the ability to view cards and
              receive notifications.
              <br />
              <br />
              Use the button below to download the app today!
            </Text>
            <div className="text-center w-48 m-auto mt-8">
              {platform === 'ios' && (
                <a href="https://apps.apple.com/us/app/benepass/id1497613952" target="_blank" rel="noreferrer">
                  <img src={AppStoreBadgeImage} alt="Download on the App Store" />
                </a>
              )}
              {platform === 'android' && (
                <a
                  href="https://play.google.com/store/apps/details?id=com.getbenepass.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PlayStoreBadgeImage} alt="Get it on Google Play" />
                </a>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal.Root>
      {!open && children}
    </>
  );
};

export default MobileAppStoresModal;
