import { useFetchClient } from 'utils/src/services/DataFetchClient';
import { CacheProvider } from 'rest-hooks';
import { ThemeProvider } from 'react-components';
import { useAuthentication } from '@data/auth/transition';
import { UserWorkspaceProvider } from '@hooks/use-user-workspace';

import FetchContext from 'utils/src/context/FetchContext';
import MinScreenProvider from 'utils/src/hooks/use-min-screen';

import MobileAppStoresModal from './components/mobile-app-stores-modal';

const Providers = ({ children }: React.PropsWithChildren) => {
  const client = useFetchClient(useAuthentication);

  return (
    <CacheProvider>
      <ThemeProvider>
        <MobileAppStoresModal>
          <FetchContext.Provider value={client}>
            <MinScreenProvider
              screens={{
                sm: '640px',
                md: '768px',
                lg: '1024px',
                xl: '1280px',
                '2xl': '1536px',
              }}
            >
              <UserWorkspaceProvider>{children}</UserWorkspaceProvider>
            </MinScreenProvider>
          </FetchContext.Provider>
        </MobileAppStoresModal>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Providers;
