import { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, useLocation, useHistory } from 'react-router-dom';
import { useAuthentication } from '@data/auth/transition';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { createEnvironment } from 'utils/src/RelayEnvironment';
import { OverlayLoader, PageError, Message, SuspenseWrapper } from 'react-components';
import { ErrorBoundary } from 'react-error-boundary';
import { LogCategories, errorHandler } from 'utils/src/Logs';

import * as Sentry from '@sentry/react';
import Routes from '@pages/routes';
import current from '@config';

import { ApolloProvider } from '@data/services/apollo';
import { contactSupportMessage } from 'utils/src/Support';
import { ToastProvider } from 'react-components/src/components/toast';
import { DialogProvider } from 'react-components/src/components/dialog';
import { useUserWorkspacesQuery } from '@app/data/user-workspaces.query';
import { useUserWorkspace } from '@hooks/use-user-workspace';

import projectPackage from '../../package.json';
import Providers from './providers';
import Authenticator from './authenticator';

const { version } = projectPackage;
const { MessageTypes } = Message;

const headers = {
  'x-benepass-platform': 'web',
  'x-benepass-client': 'employee-web',
  'x-benepass-version': process.env.npm_package_version || version,
};

const WorkspaceChecker = () => {
  const [userWorkspace, setUserWorkspace] = useUserWorkspace();
  const workspaces = useUserWorkspacesQuery();

  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(location.search).get('workspace'), [location.search]);

  useEffect(() => {
    // check there's a `workspace` query param that is different from the current user workspace
    // then we will check if the workspace from query params exist in the list of workspaces that
    // the user has access to. If it does AND it's not a login required workspace, we will set the
    // user workspace to the workspace from query params.
    // After that we will remove the `workspace` query param from the URL.
    if (searchParams && userWorkspace !== searchParams && (workspaces || []).length) {
      const foundWorkspace = workspaces.find((workspace) => workspace.apiID === searchParams);

      if (foundWorkspace && foundWorkspace.loginRequired === false) {
        setUserWorkspace(searchParams);
      }

      const params = new URLSearchParams(location.search);

      params.delete('workspace');

      history.replace({
        pathname: location.pathname,
        search: `?${params.toString()}`,
      });

      // If there's no `userWorkspace` set (the `typeof` check is to double check that the provider
      // and everything needed is mounted and ready) and there's only one workspace available, we will
      // set the user workspace to the first workspace.
    } else if (typeof userWorkspace === 'string' && !userWorkspace && (workspaces || []).length === 1) {
      const [firstWorkspace] = workspaces;

      if (firstWorkspace?.loginRequired === false) {
        setUserWorkspace(firstWorkspace.apiID);
      }
    }
  }, [userWorkspace, workspaces, setUserWorkspace, searchParams, history, location.pathname, location.search]);

  return null;
};

const AppMain = () => {
  const { user, getAccessToken } = useAuthentication();
  const [userWorkspace, setUserWorkspace] = useUserWorkspace();

  const relayEnvironment = createEnvironment({
    graphQLUrl: current.api.graphql,
    getAccessTokenSilently: getAccessToken,
    email: user?.email,
    extraHeaders: {
      ...headers,
      ...(userWorkspace ? { 'x-benepass-workspace-id': userWorkspace } : {}),
    },
    errorHandler: async (error) => {
      /**
       * @notes
       *  while testing MEW there were some cases where the user ended having a workspace id set
       *  and the user was not in the same workspace as the one they were trying to access
       *  so as workaround we will clear the user workspace and that will triger the checks to run
       *  again. We will be adding more specific handlers and checks for certain queries and then
       *  we'll be able to remove this handler.
       */
      if (error.message === 'detail: Workspace not found.') {
        await setUserWorkspace('');
      }
    },
  });

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment as never}>
      <ApolloProvider>
        <SuspenseWrapper loader={<OverlayLoader message="Loading your data..." />}>
          <ErrorBoundary
            FallbackComponent={() => (
              <PageError title="Server Unreachable">
                <Message type={MessageTypes.ERROR}>
                  Something is wrong on Benepass Servers. {contactSupportMessage}
                </Message>
              </PageError>
            )}
            onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
          >
            <WorkspaceChecker />
            <DialogProvider />
            <ToastProvider />
            <Routes />
          </ErrorBoundary>
        </SuspenseWrapper>
      </ApolloProvider>
    </RelayEnvironmentProvider>
  );
};

const App = () => (
  <Authenticator>
    <Router>
      <Providers>
        <AppMain />
      </Providers>
    </Router>
  </Authenticator>
);

export default App;
